// @ts-nocheck
import CachedIcon from "@mui/icons-material/Cached";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HeaderWithTitle from "../../components/HeaderWithTitle";
import {
  callPostApi,
  getAadhaarNumberOtp,
  submitAadhaarNumberOtp,
} from "../../configs/api";
import { callSnackbar, verificationProps } from "../../wrapper";
import AadhaarInput from "./AadhaarInput";
import AadhaarOtp from "./AadhaarOtp";
import React from "react";
import ConfirmationScreen from "../../components/ConfirmationScreen";
import { routes } from "../../configs/routes";

enum AADHAAR_SCREENS {
  EnterAadhaar,
  EnterOtp,
  ShowConfirmation,
}

export default function Aadhaar({
  onError,
  onSuccess,
  onBack,
  token,
  disclaimer,
  confirmationRequired,
}: verificationProps) {
  const [screen, setScreen] = useState(AADHAAR_SCREENS.EnterAadhaar);
  const [aadhaar, setAadhaar] = useState<string>("");
  const [refId, setRefId] = useState<string>("");
  const [messageForEnteringOtp, setMessageForEnteringOtp] =
    useState<string>("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const [timer, setTimer] = useState(60);

  const submitText =
    screen === AADHAAR_SCREENS.EnterAadhaar
      ? "Verify with Aadhaar"
      : "Continue";

  const initTimer = () => {
    setTimer(60);
    const id = setInterval(() => {
      setTimer((seconds) => {
        if (seconds == 0) {
          clearInterval(id);
          return seconds;
        }
        return seconds - 1;
      });
    }, 1000);
  };

  const getOtp = async () => {
    const res = await getAadhaarNumberOtp(aadhaar, token);
    if (res.ref_id) {
      setOtp("");
      setRefId(res.ref_id);
      setMessageForEnteringOtp(res.message);
      setScreen(AADHAAR_SCREENS.EnterOtp);
      initTimer();
      callSnackbar("success", res?.message);
    } else {
      onError("aadhaar", res);
    }
  };

  const triggerConfirm = async () => {
    const res = await callPostApi(
      routes.submitAadhaarConfirmation,
      "",
      { verificationName: "aadhaar" },
      {},
      token
    );
    if (res?.data?.code === 0) {
      onSuccess("aadhaar", res, null); // check and update
    } else {
      onError("aadhaar", res);
    }
  };

  async function handleAadhaarSubmit() {
    try {
      setLoading(true);

      if (screen === AADHAAR_SCREENS.EnterAadhaar) {
        await getOtp();
      } else if (screen === AADHAAR_SCREENS.EnterOtp) {
        const res = await submitAadhaarNumberOtp(otp, refId, token);
        if (!res) {
          return;
        }

        if (confirmationRequired) {
          setConfirmationData(res);
          setScreen(AADHAAR_SCREENS.ShowConfirmation);
        } else {
          await triggerConfirm();
        }
      } else {
        await triggerConfirm();
      }
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  console.log("aadhaar", aadhaar);
  return (
    <Container
      maxWidth="sm"
      sx={{
        padding: "12px",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <HeaderWithTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "nowrap",
          height: "100%",
          margin: "auto",
          padding: 0,
        }}
        title={
          screen === AADHAAR_SCREENS.ShowConfirmation
            ? "Please verify your identity details"
            : "Verify Aadhaar"
        }
        onBack={onBack}
      >
        {screen !== AADHAAR_SCREENS.ShowConfirmation && (
          <Box
            sx={{
              borderRadius: "16px",
              backgroundColor: "#F6F7FF",
              height: "131px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0 20px 0",
            }}
          >
            <img
              src="https://storage.googleapis.com/indisi-images-dev/b79d0a3d5bf223d778140452c72d1b7cf91f11c121b0e868bd0016bda316805c"
              alt="aadhaar background"
            />
          </Box>
        )}

        {screen === AADHAAR_SCREENS.EnterAadhaar && (
          <>
            <FormLabel
              sx={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px" }}
            >
              Enter Your Aadhaar Number to continue
            </FormLabel>

            <AadhaarInput
              value={aadhaar}
              valueLength={6}
              onChange={(value) => setAadhaar(value)}
            />
          </>
        )}
        {screen === AADHAAR_SCREENS.EnterOtp && (
          <>
            <FormLabel
              sx={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px" }}
            >
              Enter Aadhaar Otp to Continue
            </FormLabel>

            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              {/* The verification code was sent to +91*********0512 (Valid for 10 min) */}
              {messageForEnteringOtp}
            </Typography>

            <AadhaarOtp otp={otp} setOtp={setOtp} />

            {timer > 0 ? (
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  textAlign: "left",
                  marginTop: "15px",
                }}
              >
                Resend OTP in {timer < 10 ? `0:0${timer}` : `0:${timer}`}{" "}
                seconds
              </Typography>
            ) : (
              <LoadingButton
                loading={loadingResend}
                variant="text"
                color="primary"
                sx={{ color: "#08315F" }}
                startIcon={<CachedIcon sx={{ color: "#8F8F92" }} />}
                onClick={async () => {
                  setLoadingResend(true);
                  await getOtp();
                  setLoadingResend(false);
                }}
              >
                Resend verification code
              </LoadingButton>
            )}
          </>
        )}

        {screen === AADHAAR_SCREENS.ShowConfirmation && (
          <ConfirmationScreen data={confirmationData} />
        )}
      </HeaderWithTitle>

      <Box>
        <FormControlLabel
          required
          control={
            <Checkbox
              checked={checkedDisclaimer}
              disabled={loading || loadingResend}
              onChange={(e) => setCheckedDisclaimer(e.target.checked)}
            />
          }
          label={
            <span style={{ fontSize: "12px", lineHeight: "16px" }}>
              {disclaimer}
            </span>
          }
        />

        <LoadingButton
          disabled={
            !checkedDisclaimer ||
            aadhaar.length !== 12 ||
            (screen === AADHAAR_SCREENS.EnterOtp && otp.length != 6)
          }
          loading={loading}
          onClick={handleAadhaarSubmit}
          variant="contained"
          color="primary"
          sx={{ width: "100%", marginTop: "20px" }}
        >
          {submitText}
        </LoadingButton>
      </Box>
    </Container>
  );
}
