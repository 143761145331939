// @ts-nocheck
import { Box, OutlinedInput } from '@mui/material';
import React, { useRef, useState } from 'react';

const RE_DIGIT = new RegExp(/^\d+$/);

type AadhaarInputProps = {
    value: string;
    valueLength: number;
    onChange: (val: string) => void;
}

export default function AadhaarInput({ value, valueLength, onChange }: AadhaarInputProps) {
    const ref1 = useRef<HTMLInputElement>(null);
    const ref2 = useRef<HTMLInputElement>(null);
    const ref3 = useRef<HTMLInputElement>(null);

    const [aadharNumber, setAadharNumber] = useState<string[]>(['', '', '']);

    const handleAadharNumberChange = (index: number, value: string): void => {
        const newAadharNumber = [...aadharNumber];
        newAadharNumber[index] = value;
        setAadharNumber(newAadharNumber);
        onChange(newAadharNumber.join(''))
    };

    return (
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', marginTop: '8px' }}>
            {aadharNumber.map((value, index) => (
                <OutlinedInput
                    key={index}
                    value={value}
                    placeholder='0000'
                    autoFocus={index === 0}
                    inputProps={{
                        inputmode: 'numeric',
                        style: {
                            border: '1px solid #E7EDF6',
                            borderRadius: '4px',
                            fontWeight: 400,
                            padding: '8px 0px',
                            color: value ? '#404052' : '#66747F',
                            fontSize: '20px',
                            letterSpacing: '2px',
                            textAlign: 'center'
                        },
                        ref: index === 0 ? ref1 : index === 1 ? ref2 : ref3,
                        maxLength: 4,
                    }}
                    onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                            const newValue = e.target.value;
                            if (newValue.length >= 4 && index < aadharNumber.length - 1) {
                                if (index === 0) {
                                    ref2.current?.focus();
                                } else if (index === 1) {
                                    ref3.current?.focus();
                                }
                            } else if (newValue.length > 4 && index === aadharNumber.length - 1) {
                                ref3.current?.blur();
                                return;
                            }

                            handleAadharNumberChange(index, newValue);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (index === 0 || e.currentTarget.value || e.key !== 'Backspace') {
                            return;
                        }

                        if (index === 1) {
                            ref1.current?.focus();
                        } else if (index === 2) {
                            ref2.current?.focus();
                        }
                    }}
                />
            ))}
        </Box>
    )
}