// @ts-nocheck
import { CameraAlt, CameraswitchOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { FacingMode } from 'react-camera-pro/dist/components/Camera/types';
import { MAX_WIDTH, MAX_WIDTH_HALF, isDesktop } from '../../helpers';


const WebcamCapture = ({ setImage, buttonRef, flipButtonRef, aspectRatio }: any) => {
  const camViewRef = useRef(null);

  const [webcamFacingMode, setWebcamFacingMode] = useState<'enviornment' | 'user'>('environment');

  const webcamRef = React.useRef<any>(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current?.takePhoto();
      console.log('imageSrc', imageSrc);
      setImage(imageSrc);
    },
    [setImage, webcamRef]
  );
  console.log(webcamFacingMode);

  // useEffect(() => {
  // const bbox = camViewRef.current.getBoundingClientRect()
  // setCamView(bbox)

  // }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: '1em', 
        width: '100%',
         maxWidth: `${MAX_WIDTH}px`,
        background: '#ffffff', height: '100%', margin: 'auto',
      }}>

      <div
        ref={camViewRef} style={{
          
          width: '100%',
           maxWidth: `${MAX_WIDTH}px`,
          border: '3.5px dashed #FFFFFF',
          // width: isDesktop() ? '50%' : '100%'
        }}>
        <Camera
          ref={webcamRef}
          facingMode={webcamFacingMode}
          aspectRatio={aspectRatio}
          errorMessages={{
            noCameraAccessible: undefined,
            permissionDenied: undefined,
            switchCamera: undefined,
            canvas: undefined
          }} />
      </div>
      {/* <img src={rectangle} alt="rectangle" style={{
                position: 'absolute',
                ...camView,
                zIndex: 9999
            }} /> */}
      <Button
        ref={buttonRef}
        variant='outlined'
        color="secondary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF
        }}
        onClick={() => {
          capture();
        }}>
        <CameraAlt />
        &nbsp;
        Capture
      </Button>

      <Button
        ref={flipButtonRef}
        variant='outlined'
        color="primary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'absolute',
          top: '5em',
          right: '2em',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF
        }}
        onClick={() => {
          setWebcamFacingMode((prev) => prev === 'user' ? 'environment' : 'user');
          webcamRef.current.switchCamera();
        }}>
        <CameraswitchOutlined />
        &nbsp;
        Flip Camera
      </Button>
    </Grid>
  );
};

export default WebcamCapture;
