
export const base64ToBinary = async (url: string) => {
    return fetch(url)
        .then((res) => res.blob());
};

export function isDesktop() {
    if (1000 > 750) { return true; }
    else { return false; }
}

export function isValidDate(d: Date) {
    return isNaN(d.getTime());
  }

export const MAX_WIDTH =  1000;
export const MAX_WIDTH_HALF = isDesktop() ? 1000 / 6 : (1000 / 2) - 10;