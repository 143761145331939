// @ts-nocheck
import React from 'react'
import OTPInput from 'react-otp-input'

function AadhaarOtp({ otp, setOtp }: AadhaarOtpProps) {
    return (
        <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span style={{ width: '6px' }}></span>}
            renderInput={(inputProps) => (
                <input {...inputProps} />
            )}
            containerStyle={{
                margin: '28px 0',
            }}
            inputStyle={{
                width: '48px',
                height: '64px',
                borderRadius: '8px',
                borderWidth: 0,
                boxShadow: '0px 2px 8px 0px #ADADAD29',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#052242',
            }}
            inputType='tel'
        />
    )
}

type AadhaarOtpProps = {
    otp: string;
    setOtp: (otp: string) => void;
}

export default AadhaarOtp
