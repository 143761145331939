// @ts-nocheck
import React from 'react'
import { ArrowBack } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';

export default function HeaderWithTitle({ title = '', sx = {},
  isBackButtonDisabled = false,
  disableBack = false,
  children = <></>,
  onBack = () => { } }: any) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderRadius: '8px',
        margin: '1em',
        flexWrap: 'nowrap',
        width: '100%',
        ...sx
      }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          borderRadius: '8px',
          flexWrap: 'nowrap',
          marginBottom: '1em'
        }}>
        {disableBack ? null : (
          <Grid item>
            <IconButton
              color="inherit"
              edge="end"
              disabled={isBackButtonDisabled}
              onClick={onBack}
              sx={{ margin: 0, marginTop: '5px', padding: 0, marginRight: '1em' }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>)}
        <Grid item xs={12}>
          <Typography
            variant="h3" sx={{
              margin: 0,
              textAlign: 'left',
            }}>

            {title}
          </Typography>
        </Grid>
      </Grid>
      {children}

    </Grid>
  );
}
