import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { callSnackbar } from '../wrapper';
import { getEndpoint } from './routes';

const addInterceptors = (instance: AxiosInstance) => {
    // Add a request interceptor
    instance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            console.error('Intercepting request error', error);
            callSnackbar('error', error.message);
            // return Promise.reject(error);
        }
    );

    // Add a response interceptor
    instance.interceptors.response.use(
        (response: AxiosResponse<any, any>): any => {
            console.log('response:interceptors:', response);
            if (response.status === 504) {
                callSnackbar('error', 'Server is down, please try again later');
            }

            if (response.status === 401) {
                // logout();
                return null;
            }

            return response;
        },
        (error) => {
            console.error('Intercepting outside 2xx responce error,interceptors:', error.message, error);
            
            callSnackbar('error', error?.response?.data?.message ?? error?.message);
            if (error?.response?.status === 401) {
                // logout();
                return null;
            }

            if (error?.response?.data?.code !== 104) {
                console.log('Intercepting error: ', error);
                // if (!error?.response?.data?.message) {
                // callSnackbar('error', error?.response?.data?.message ?? error?.message);
                // }
            }
            return Promise.reject(error);
        }
    );
};

const TIMEOUT_MILLISECONDS = 10 * 60 * 1000;

const disableCacheInInstance = (ax: any): any => {
    ax.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    };
    return ax;
};

let axiosBaseInstance
let axiosIssuerInstance
let axiosRekognitionInstance 

const createInstances = () => {
    let baseURLs = {
        base: `${getEndpoint().verification}`,
        issuer: `${getEndpoint().issuer}`,
        rekognition: getEndpoint().rekognition
    };
    
    axiosBaseInstance = axios.create({
        baseURL: baseURLs.base,
        timeout: TIMEOUT_MILLISECONDS ?? 10000,
    });
    addInterceptors(disableCacheInInstance(axiosBaseInstance));
    
    axiosIssuerInstance = axios.create({
        baseURL: baseURLs.issuer,
        timeout: TIMEOUT_MILLISECONDS ?? 10000,
    });
    addInterceptors(disableCacheInInstance(axiosIssuerInstance));
    
    axiosRekognitionInstance = axios.create({
        baseURL: baseURLs.rekognition,
        timeout: TIMEOUT_MILLISECONDS ?? 10000,
    });
    addInterceptors(disableCacheInInstance(axiosRekognitionInstance));
}
export {
    axiosBaseInstance,
    axiosIssuerInstance,
    axiosRekognitionInstance,
    createInstances
};

