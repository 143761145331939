// @ts-nocheck
/* eslint-disable eqeqeq */
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
// import { faceMatch } from "../../Config/api";
import { Auth } from "aws-amplify";
import { getSessionId, getSessionToken, submitLivenessData } from "../../configs/api";
import { verificationProps } from "../../wrapper";

const REACT_APP_IDENTITYPOOL_ID="ap-south-1:3f04d05a-75eb-43db-be11-48f55b854c6a"
const REACT_APP_REGION="ap-south-1"
const REACT_APP_USERPOOL_ID="ap-south-1_Texgh0wCq"
const REACT_APP_WEBCLIENT_ID="5pjtmaoqigt7u16f32q35qgru2"

Auth.configure({
    "Auth": {
      "identityPoolId": REACT_APP_IDENTITYPOOL_ID,
      "region": REACT_APP_REGION,
      "userPoolId": REACT_APP_USERPOOL_ID,
      "mandatorySignIn": false,
      "userPoolWebClientId": REACT_APP_WEBCLIENT_ID
    }
});

function FaceLiveness({ onError, onSuccess, onBack, token }: verificationProps) {
  const [loading, setLoading] = React.useState(true);
  const [sessionId, setSessionId] = React.useState(null);

  const fetchSessionToken = async () => {
    /*
     * API call to create the Face Liveness Session
     */
    setLoading(true);
    const sToken = await getSessionToken();
    console.log("sToken", sToken)
    const sessionIdFromParams = await getSessionId(sToken);
    setSessionId(sessionIdFromParams);
    setLoading(false);
  };

  useEffect(() => {
    fetchSessionToken();
  }, []);

  const restartProcess = async () => {
    setSessionId('')
    await fetchSessionToken();
  };
  /*
   * Get the Face Liveness Session Result
   */
  const handleAnalysisComplete = async (data) => {
    /*
     * API call to get the Face Liveness Session result
     */
    console.log("handleAnalysisComplete", data);
    // faceLivenessAnalysis(data?.data);
    const response = await submitLivenessData(sessionId, token)
    if(response){
      onSuccess()
    }
    else{
      onError()
    }
  };

  console.log("sessionId", sessionId);

  return (
    <Box>
        {sessionId && <FaceLivenessDetector
            sessionId={sessionId}
            region="ap-south-1"
            onAnalysisComplete={handleAnalysisComplete}
            onError={(error) => {
                console.error(error);
            // redirectToApp()
            }}
        components={{
          ErrorView: ({ children }) => {
            document.querySelector(".amplify-liveness-cancel-container").style.display = "none";
            return (
              <Card
                variant="error" sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  rowGap: "10px"
                }}>
                <ErrorOutline color="error" sx={{ margin: "2px", fontSize: "19px" }} />
                <Typography variant="h4" sx={{fontWeight: "400"}}>
                  Face is not aligned properly within the stipulated time
                </Typography>
                <Button
                  onClick={async () => {
                   await restartProcess()
                  }}
                >
                  Try Again
                </Button>
              </Card>
            );
          },
        }}
      />}
    </Box>
  );
}

export default FaceLiveness;
