// @ts-nocheck
import { createTheme } from '@mui/material/styles';
const HEIGHT_OF_TOOLBAR2 = 40;
export const palette = {
  white: {
    main: '#ffffff',
    light: '#ffffff',
    contrastText: '#000000'
  },
  primary: {
    main: '#044DA1',
    light: '#E4F1FF',
    contrastText: '#fff'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    main: '#F8A908',
    light: '#FFEFDC',
    contrastText: '#fff',
    veryLight: '#fcf8f4'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  grey: {
    main: '#ACACAE',
    light: '#DADADA',
    contrastText: '#fff'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  black: {
    main: '#000000',
    light: '#ffffff',
    contrastText: '#fff'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  brand: {
    main: '#044DA1',
    light: '#ffffff',
    contrastText: '#fff'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  grey1: {
    main: '#66747F',
    light: '#FFFFFF',
    contrastText: '#fff'
  },
  secondary2: {
    main: '#515151',
    light: '#ffffff',
    contrastText: '#ffffff',
  },
  background: {
    first: '#fff',
    second: '#F6F7FF',
    third: '#DEE4F4',
    fourth: '#eee',
    fifth: '#EEF2FB',
    sixth: '#A9D2EF',
    seventh: '#DFF1FF',
  },
  border: {
    main: '#DADADA',
    dark: '#79747e'
  },
  success: {
    main: '#239D56',
    light: '#DEF6E7',
    contrastText: '#ffffff'
  },
  pending: {
    main: '#FFA800',
    light: '#FEF8EC'
  },
  // warning: {
  //   main: '#F8A908',
  //   light: '#DADADA',
  //   contrastText: '#fff'
  // },
  error: {
    main: '#C84646',
    regular: '#D73F3F',
    light: '#FFEFEF'
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  trential: {
    main: '#037FFF',
    light: '#037FFF',
    contrastText: '#fff'
    // light: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
};

function isDesktop(): boolean {
  if (1000 > 750) {
    return true;
  }

  return false;
}

export const MAX_WIDTH = !isDesktop ? 1000 / 3 : 1000;

const theme = createTheme({
  // @ts-ignore
  palette: palette,
  spacing: (factor: any) => `${factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1630,
    },
  },
  overrides: {
    'MuiTooltip-popper': {
      tooltip: {
        fontSize: '42px',
      },
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true
      },
      styleOverrides: {
        // Name of the slot
        // @ts-ignore
        root: ({ ownerState }: any) => ({
          variants: {},

          fontSize: '14px',
          textTransform: 'initial',
          borderRadius: '8px',
          padding: '1em 2em 1em 2em',
          width: '100%',
          fontWeight: 700,
          // maxWidth: `${MAX_WIDTH}px`,
          minWidth: { xs: '80px', sm: '100px', md: '165px', lg: `${MAX_WIDTH}px` },
          ...(ownerState.variant === 'contained' && {
            '&:hover': {
              opacity: 1,
              backgroundColor: palette.primary.main,
            }
          }),
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#ffffff',
            fontWeight: 'bold',
            padding: '0.9em 2em 0.9em 2em',
            borderWidth: '2px !important',
          }),
          ...(ownerState.variant === 'text' && {
            width: 'fit-content'
          }),
        }),
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          borderRadius: '8px',
          textAlign: 'left',
          border: '1px solid #CCCCCC',
          overflow: 'visible',
          height: 'fit-content'
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: '14px'
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '24px'
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          minHeight: `${HEIGHT_OF_TOOLBAR2}px !important`
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        // Name of the slot
        tooltip: {
          // Some CSS
          fontSize: '10px'
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: '12px',
          marginTop: '-1em',
          marginBottom: '1em',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none'
        },
      },
    },
  },
});

theme.typography.button = {
  textTransform: 'none'
};
theme.typography.h1 = {
  color: '#404052'
};
theme.typography.h2 = {
  color: '#404052'
};
theme.typography.h3 = {
  color: '#404052'
};
theme.typography.h4 = {
  color: '#404052'
};

theme.typography.h5 = {
  color: '#404052',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '20px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12.4px',
    lineHeight: '15px'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '14px',
    lineHeight: '20px'
  },
};

theme.typography.h6 = {
  color: '#404052',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12.4px',
    lineHeight: '15px'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '14px',
    lineHeight: '20px'
  },
};

theme.typography.body1 = {

  fontSize: '14px',
  color: '#404052',
  margin: '0.8em 0'
};

theme.typography.body2 = {

  fontSize: '12px',
  fontWeight: 400,
  color: '#222222',
  margin: '0.8em 0'
};

export default theme;
