export const injectCss = () => {
    // create a style element
    const style = document.createElement('style');

    // add the CSS as a string using template literals
    style.appendChild(document.createTextNode(`

#main-container-client-verification-sdk body{
    font-size: 10px;
}

/* Firefox */
#main-container-client-verification-sdk input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

#notistack-snackbar {
width: 100%;
padding: 1em;

}
    `
    ));

    // add it to the head
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
}