// @ts-nocheck
import { base64ToBinary } from "../helpers";
import { axiosBaseInstance, axiosRekognitionInstance } from "./axios";
import { routes } from "./routes";

export const getAxiosHeader = (token: string, x = {}) => {
  return ({
    headers: {
      'Authorization': `Bearer ${token}`,
      ...x
    }
  });
};

export const uploadDocGetAttributes = async (route: string, queryParamStr: string, images: string[], token: string) => {
  const formData = new FormData();
  console.log('images:--', images);

  try {
    for (const img of images) {
      const imageInBinary = await base64ToBinary(img);
      formData.append(`file`, imageInBinary);
    }

    return await callPostApi(route, queryParamStr, formData, { 'Content-Type': 'multipart/form-data' }, token);
  } catch (error) {
  }
};

export const callPostApi = async (url: string, queryParamStr:string, data: object, headers: object = {}, token: string): any => {
  try {
    const res = await axiosBaseInstance.post<{ code: number }>(
      url + queryParamStr,
      data,
      getAxiosHeader(token, headers)
    );

    return res;

  } catch (error) {
    return false;
  }
}

export const getAadhaarNumberOtp = async (n: string, token: string) => {
  return axiosBaseInstance.post(
    routes.getAadhaarOtp,
    {
      "aadhaarNumber": n
    }, getAxiosHeader(token)
  ).then((res) => {
    if (res?.data?.code === 0) {
      // return res?.data?.data?.attributes;
      return res?.data?.data;
    }
    return false;
  }).catch((e) => {
    // console.log(e)
    // callSnackbar('error', e.message)
    return false;
  });
};

export const submitAadhaarNumberOtp = async (otp: string, refId: string, token: string) => {
  try {
    const res = await axiosBaseInstance.post(routes.submitAadhaarOtp + "?backendConfirmationRequired=true",
      {
        "ekycOtp": otp,
        "refId": refId,
        "digiLocker": false,
      },
      getAxiosHeader(token));

    if (res?.data?.code === 0) {
      return res?.data?.data;
    }

  } catch (error) {
    // callSnackbar('error', e.message)
  }

  return null;
};

// AWS Rekognition for Liveness
export const getSessionToken = async (): Promise<any | false> => {
  console.log("getSessionToken called");

  try {
    const res = await axiosRekognitionInstance.get(
      routes.liveness.sessionToken
      , {
        headers: {
          "X-API-KEY": process.env.REACT_APP_AWS_REKOGNITION_X_API_KEY,
        }
      }
    )
    console.log("token", res.data);
    return res.data.data;
  } catch (err) {
    console.log("getSessionToken Error", JSON.stringify(err));
    return false;
  }
};

// AWS Rekognition for Liveness
export const getSessionId = async (sessionToken: string): Promise<any | false> => {
  console.log("getSessionId called");
  try {
    const res = await axiosRekognitionInstance.get(
      routes.liveness.sessionId,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_AWS_REKOGNITION_X_API_KEY,
          "Authorization": `Bearer ${sessionToken}`,
        }
      }
    )

    console.log("getSessionId", res.data.data);
    return res.data.data;
  } catch (err) {
    console.log("getSessionId Error", err);
    return false;
  }

};

// AWS Rekognition for Liveness
export const submitLivenessData = async (sessionId: string, token: string): Promise<any | false> => {
  console.log("submitLivenessData called", token);
  try {
    const res = await axiosBaseInstance.post(
      routes.liveness.submit,
      { sessionId },
      getAxiosHeader(token)
    )

    console.log("submitLivenessData", res.data.data);
    return res.data.data;
  } catch (err) {
    console.log("submitLivenessData Error", err);
    return false;
  }

};

// Driving License Captcha
export const getDlCaptcha = async (token): Promise<any | false> => {
  try {
    const res = await axiosBaseInstance.get(
      routes.drivingLicenseCaptcha,
      getAxiosHeader(token)
    )
    return res.data.data;
  } catch (err) {
    return false;
  }
};
