import { DoNotDisturbAltOutlined, RemoveRedEyeOutlined, WbSunnyOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

// Import from CDN
const PassportInstructionsFront = "https://storage.googleapis.com/indisi-wallet-app/mexican-id-cover.svg"
const PassportInstructionsBack = "https://storage.googleapis.com/indisi-images-dev/PassportInstructionsBack.svg"

type passportInstructionsProps = {
  isFront: boolean;
}

const INSTRUCTION_TIPS: { logo: any, text: string }[] = [
  {
    logo: <WbSunnyOutlined sx={{ fontSize: '12px' }} />,
    text: 'Find an area with good lighting'
  },
  {
    logo: <RemoveRedEyeOutlined sx={{ fontSize: '12px' }} />,
    text: 'Make sure all details are visible'
  },
  {
    logo: <DoNotDisturbAltOutlined sx={{ fontSize: '12px' }} />,
    text: 'Photocopies are not accepted'
  },
]

function PassportInstructions({ isFront }: passportInstructionsProps) {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '22px' }}>
        <img src={isFront ? PassportInstructionsFront : PassportInstructionsBack} alt="upload passport" style={{maxHeight: '40vh'}} />
      </Box>

      <Box sx={{ display: 'block' }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '32px',
            textAlign: 'center',
            width: '100%',
            marginBottom: '8px'
          }}
        >
          How to scan
        </Typography>

        <Typography
          variant="body1"
          color="grey1.main"
          sx={{ margin: '0px' }}
        >
          Position the front page of your card in the frame and then click capture button.
        </Typography>

        <Typography variant="body1"
          sx={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '22px',
            margin: '8px 0px 6px'
          }}
        >
          Tips:
        </Typography>

        {INSTRUCTION_TIPS.map((instruction, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{
              width: '100%',
              display: 'flex',
              columnGap: '8px',
              textAlign: 'left',
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0px 3px',
              justifyContent: 'flex-start',
            }}>
            {instruction.logo}

            <span
              style={{
                color: '#66747F',
                fontWeight: '12px',
                lineHeight: '22px'
              }}
            >
              {instruction.text}
            </span>
          </Typography>
        ))}
      </Box>
    </>
  )
}

export default PassportInstructions
