import React, { useCallback } from 'react';
import { Box } from '@mui/material';

export default function ConfirmationScreen({ data }: { data: any }) {

    const camelCaseToTitleCase = useCallback((str: string) => (
        str
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str: string) => str.toUpperCase())
    ), []);

    return (
        <Box
            sx={{
                padding: '5px',
                backgroundColor: '#E7EDF6',
                borderRadius: '5px',
                margin: '10px 0px 0px',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    margin: '0px',
                    width: '99%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1px',
                    padding: '2px',
                    maxHeight: '62vh',
                    overflowY: 'auto'
                }}
            >
                {(Object.keys(data)).map((dataKey) => (
                    <>
                        {data[dataKey] &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                    padding: '9px 14px',
                                    margin: 0,
                                    backgroundColor: '#FFFFFF'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '20px'
                                    }}
                                >
                                    {camelCaseToTitleCase(dataKey)}
                                </span>
                                {dataKey === 'photo' &&
                                    <img
                                        src={`data:image/png;base64,${data[dataKey]}`}
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            border: '1px solid #FFFFFF',
                                            borderRadius: '4px'
                                        }}
                                    />
                                }

                                {dataKey !== 'photo' &&
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            lineHeight: '18px'
                                        }}
                                    >
                                        {data[dataKey]}
                                    </span>
                                }
                            </Box>
                        }
                    </>
                ))}
            </Box>
        </Box>
    )
}
